import './App.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'
import { useTranslation } from 'react-i18next';
import { useEffect } from "react"
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import logo from "./assets/Livreur.svg"
function App() {
  const { t, i18n } = useTranslation();
  return (
    <div className="App">
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand><img src={logo} width="100"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Form.Select aria-label="Default select example" className='mt-3 mt-lg-0' onChange={(e) => i18n.changeLanguage(e.target.value)}>
                <option value="en">English</option>
                <option value="he">Hebrew</option>
              </Form.Select>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Container className='mt-3'>
        <h3 className='fw-bold mb-3 text-center'>{t('Heading')}</h3>
        {t('Privacy')}
      </Container>
    </div>
  );
}

export default App;
