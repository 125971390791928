import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: {
            "Privacy": `Privacy Policy for Livreur Delivery App

      At Livreur, we take your privacy very seriously. This privacy policy outlines how we collect, use, and protect your personal information when you use our delivery app.
      
      Information We Collect
      When you use our app, we may collect the following information:
      
      Your name, email address, phone number, and delivery address
      Your delivery preferences, such as the type of delivery service you require and the time you want the delivery to be made
      Your payment information, including credit card details or other payment methods
      Your location data, including your current location and the delivery address
      We may also collect information about your device, such as the device type, operating system, and browser type.
      
      How We Use Your Information
      We may use your information to:
      
      Provide delivery services, including processing your orders and payments
      Communicate with you about your deliveries and provide customer support
      Improve our app and services, including analyzing app usage and trends
      Send you promotional offers and other marketing materials
      We may also use your information to comply with legal obligations, such as responding to court orders or law enforcement requests.
      
      How We Protect Your Information
      We take the security of your personal information very seriously. We use industry-standard security measures to protect your information from unauthorized access, disclosure, and destruction.
      
      How We Share Your Information
      We may share your personal information with third-party service providers who help us provide our services, such as payment processors and delivery drivers. We may also share your information with law enforcement agencies or other third parties when required by law or to protect our rights and property.
      
      Your Rights and Choices
      You have the right to access, correct, or delete your personal information. You can also opt out of receiving promotional offers and marketing materials at any time by contacting us.
      
      Updates to this Policy
      We may update this privacy policy from time to time. If we make any material changes to this policy, we will notify you by email or by posting a notice in the app.
      
      Contact Us
      If you have any questions or concerns about our privacy policy, please contact us at info@stayinnovation.com
      `,
            "Heading":"Privacy Policy"
        }
    },
    he: {
        translation: {
            "Privacy": `
      מדיניות פרטיות עבור אפליקציית משלוחים Livreur
      
      בליברור, אנו לוקחים את הפרטיות שלך ברצינות רבה. מדיניות פרטיות זו מתארת כיצד אנו אוספים, משתמשים ומגנים על המידע האישי שלך כאשר אתה משתמש באפליקציית המשלוחים שלנו.
      
      מידע שאנו אוספים
      כאשר אתה משתמש באפליקציה שלנו, אנו עשויים לאסוף את המידע הבא:
      
      שמך, כתובת דואר אלקטרוני, מספר טלפון וכתובת למשלוח
      העדפות המשלוח שלך, כגון סוג שירות המשלוחים שאתה צריך והשעה שבה אתה רוצה שהמשלוח יבוצע
      פרטי התשלום שלך, כולל פרטי כרטיס אשראי או אמצעי תשלום אחרים
      נתוני המיקום שלך, כולל המיקום הנוכחי שלך וכתובת המשלוח
      אנו עשויים גם לאסוף מידע על המכשיר שלך, כגון סוג המכשיר, מערכת ההפעלה וסוג הדפדפן.
      
      כיצד אנו משתמשים במידע שלך
      אנו עשויים להשתמש במידע שלך כדי:
      
      לספק שירותי משלוח, כולל עיבוד ההזמנות והתשלומים שלך
      לתקשר איתך לגבי המשלוחים שלך ולספק תמיכת לקוחות
      שפר את האפליקציה והשירותים שלנו, כולל ניתוח השימוש באפליקציות ומגמות
      שלח לך הצעות קידום מכירות וחומרי שיווק אחרים
      אנו עשויים גם להשתמש במידע שלך כדי לעמוד בהתחייבויות משפטיות, כגון תגובה לצווי בית משפט או בקשות של אכיפת חוק.
      
      כיצד אנו מגנים על המידע שלך
      אנו מתייחסים ברצינות רבה לאבטחת המידע האישי שלך. אנו משתמשים באמצעי אבטחה סטנדרטיים בתעשייה כדי להגן על המידע שלך מפני גישה בלתי מורשית, חשיפה והרס.
      
      כיצד אנו משתפים את המידע שלך
      אנו עשויים לשתף את המידע האישי שלך עם ספקי שירות של צד שלישי שעוזרים לנו לספק את השירותים שלנו, כגון מעבדי תשלומים ונהגי משלוח. אנו עשויים גם לשתף את המידע שלך עם סוכנויות אכיפת חוק או צדדים שלישיים אחרים כאשר הדבר נדרש על פי חוק או כדי להגן על הזכויות והקניין שלנו.
      
      הזכויות והבחירות שלך
      יש לך את הזכות לגשת, לתקן או למחוק את המידע האישי שלך. תוכל גם לבטל את הסכמתך לקבלת הצעות קידום מכירות וחומרי שיווק בכל עת על ידי יצירת קשר.
      
      עדכונים למדיניות זו
      אנו עשויים לעדכן את מדיניות הפרטיות הזו מעת לעת. אם נבצע שינויים מהותיים במדיניות זו, נודיע לך באימייל או על ידי פרסום הודעה באפליקציה.
      
      צור קשר
      אם יש לך שאלות או חששות לגבי מדיניות הפרטיות שלנו, אנא צור איתנו קשר בכתובת info@stayinnovation.com
      `,
      "Heading":"מדיניות הפרטיות"
        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;